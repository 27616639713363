import React from 'react';
import { Link } from 'react-router-dom';
import './Navigation.css'; 

const Navigation = ({ isResumePage }) => {
  return (
    <nav className={`navbar ${isResumePage ? 'navbar-resume' : ''}`}>
      {/* Conditional class name added here */}
      <Link to="/" className="nav-item">About Me</Link>
      <Link to="/resume" className="nav-item">Resume</Link>
      <Link to="/projects" className="nav-item">Portfolio</Link>
      {/* ... other navigation items ... */}
    </nav>
  );
};

export default Navigation;

// Resume.js
import React from 'react';
import Navigation from './Navigation'; // Import the Navigation component
import './Resume.css'; // Ensure you have this file with appropriate styles


function Resume() {
  return (
    <div className="resume-page-container">
      <h1 className="resume-title">Resume</h1>
      <Navigation resizeForResume={true} />
      <div className="resume-content-box">
        {/* Resume Header */}
        <section className="resume-header">
          <h2>Anthony Humes</h2>
          <p>Bismarck, ND. USA | aroberthumes@gmail.com</p>
        </section>

        {/* Professional Summary Section */}
        <section className="resume-section summary-section">
          <h2 className="summary-title">Professional Summary</h2>
          <p className="summary-content">Data Expert proficient in Python, R, and SQL, specializing in predictive modeling, natural language processing (NLP), and data visualization. Demonstrated success in utilizing advanced analytics for revenue enhancement, price optimization, and creating actionable insights with tools like Tableau and Domo. Expertise in machine learning (Scikit-Learn, TensorFlow), database management (MySQL, Amazon Redshift), and upholding data integrity. Skilled at molding complex datasets into clear, actionable formats to empower data-driven decisions.</p>
        </section>

        {/* Experience Section */}
        <section className="resume-section">
        <div className="education-section">
          <h2 className="education-title">Work Experience</h2>
          <div className="education-content">
          <h4>Blox Digital - East Moline, IL</h4>
        <h4>Yield Data Analytics Manager - 2023-Present</h4>
        <ul>
          <li>Led the development of time-series forecasting in Python using an ensemble model of LSTM and XGBoost as part of the companies guaranteed revenue program. Increased accuracy to within 1.4% of company’s target.  </li>
          <li>Used CatBoost to increase revenue 19% by creating a price optimization model. The model determined the best ad price each week by strategically finding the middle ground between pageviews and revenue.</li>
          <li>Developed interactive dashboards with different visualizations such as tables, bar charts and variance line charts so customers can view their data in a more easily digestible way. Used Flask for API integration with a database and React for frontend development.</li>
          <li>Designed and implemented a chatbot to assist staff with inquiries on customer data, using Electron for the front-end and Flask for API integration on the back-end, while employing the SpaCy NLP model for processing questions from sales and customer success teams.</li>
          <li>Analyze all customers data from various sources including Google Analytics4 focusing on metrics such as revenue, impressions, click-through rates, sell-through rates, and page views. Identifying any metric declines, conducting exploratory data analysis using statistical, trend, and cohort analysis to identify the reason for the drop, and developing strategies to fix any issues. </li>
          <li>Regularly meet with stakeholders and customers to discuss insights from their data. Simplifying any complex technical details into understandable language.</li>
        </ul>
        <h4>Revenue Operations Data Analyst	2022-2023</h4>
        <ul>
          <li>Setup automated data pipelines using API integration built with Python and task scheduler on a virtual machine. Used a combination of Google BigQuery and dbt to clean and manipulate the data coming from the API call, making the data easier to handle and understand. Stored the data in either Domo or Google’s Cloud Platform.</li>
          <li>Oversaw database management and optimization allowing for easy data storage and retrieval. Setup monitoring alerts that alerted to any data attempting to be stored in the wrong format. Increased security by setting up audit logs to monitor what changes were made to the database such as SQL changes.</li>
          <li>Created a report with visualizations that gave our customers guidance on what the optimal pricing for their local ad spots should be. The report showed whether they were overcharging or undercharging on their ad spots.</li>
        </ul>
        <h4>KFYR-TV - Bismarck, ND</h4>
        <h4>Assistant News Director	2014-2022</h4>
        <ul>
          <li>Built databases with MySQL and Excel's advanced features. I also used Tableau for in-depth analysis and presentation of COVID-19 trends, giving our audience an insight on the current and potential future trend of COVID-19.</li>
          <li>Performed monthly reviews of Facebook insights which led to 25% boost in page views. This analysis helped the station improve its social media strategy, enhancing engagement with our viewers.</li>
          <li>Used SQL and Tableau for comprehensive viewer ratings analysis helping the station refine its advertising budgets and tactics, ensuring they resonated well with our audience's preferences and the latest trends.</li>
          <li>Led a diverse news team of 22 members, helping in the research and design of captivating newscast formats. This collaboration helped achieve a 17% increase in viewership and audience interaction.</li>
        </ul>
        </div>
        </div>
        </section>

          {/* Education Section */}
          <section className="resume-section">
          <div className="education-section">
          <h2 className="education-title">Education</h2>
          <div className="education-content">
            <p><strong>University of Massachusetts - Lowell</strong></p>
            <p>Accounting & Business Analytics / MSc, GPA: 3.7</p>
            <p><strong>Westminster College</strong></p>
            <p>Broadcast Communications / BA, GPA: 3.6</p>
          </div>
        </div>
        </section>

        {/* Skills Section */}
        <section className="skills-section">
        <h2>Skills</h2>
        <div className="skills-container">
          <ul className="skills-list">
          <li>R</li>
          <li>Python</li>
          <li>MySQL</li>
          <li>React</li>
          <li>Electron</li>
          <li>Amazon RedShift</li>
          <li>MS SQL Server</li>
          <li>Scikit-Learn</li>
          <li>Keras</li>
          <li>TensorFlow</li>
          <li>PyTorch</li>
          <li>Amazon Web Services</li>
          <li>Google Analytics 4</li>
          <li>GitHub</li>
          <li>Git</li>
          <li>Regression</li>
          <li>Clustering</li>
          <li>Boosted Trees</li>
          <li>Sentiment</li>
          <li>Time-series forecasting</li>
          <li>Deep Learning</li>
          <li>Classification</li>
          <li>Natural Language Processing</li>
          <li>JavaScript</li>
          <li>CSS</li>
          <li>HTML</li>
          <li>Flask</li>
          <li>Google BigQuery</li>
          <li>dbt</li>
          <li>API</li>
          <li>AWS</li>
          <li>Google Cloud Platform</li>
          </ul>
          </div>
        </section>

                {/* Programs Section */}
                <section className="programs-section">
        <h2>Programs</h2>
        <div className="programs-container">
          <ul className="programs-list">
          <li>Tableau</li>
          <li>Microsoft Excel</li>
          <li>Microsoft Access</li>
          <li>Microsoft Word</li>
          <li>Domo</li>
          <li>VScode</li>
          <li>Salesforce</li>
          <li>Microsoft Teams</li>
          <li>Zoom</li>
          <li>Skype</li>
          <li>Slack</li>
          <li>Google Ad Manager</li>
          </ul>
          </div>
        </section>

        {/* Download Button */}
        <a href={`${process.env.PUBLIC_URL}/Anthony_Humes_Resume.pdf`} download="Anthony_Humes_Resume.pdf" className="download-resume-button">
          Download as PDF
        </a>
      </div>
    </div>
  );
}

export default Resume;

// AboutMe.js
import React from 'react';
import './AboutMe.css'; // Import the stylesheet for styling
// Ensure that the path matches the location and name of your image file
import profilePic from './Anthony-Humes-profile-pic.jpg'; // Import the image



function AboutMe() {
  return (
    <div className="about-me-container">
      <div className="profile-picture">
        {/* The imported image is used here as the source for the img element */}
        <img src={profilePic} alt="Profile" className="profile-picture" />
      </div>
      <div className="about-me-content">
        <h2>HI THERE</h2>
        <h3>I AM A DATA ENTHUSIAST WHO:</h3>
        <ul>
          <li>🔥 loves to solve complex data problems in any form</li>
          <li>🌐 currently excelling in the online advertising field, I have boosted client revenue by 20% through strategic use of Python, R, and data-driven methods for forecasting and dashboard creation</li>
          <li>🧬 successfully managed complex assignments involving demographic analysis and COVID database management, along with the development of visualizations</li>
          <li>📊 plays with all kinds of data structures - text, image, structured or unstructured, numerical etc.</li>
          <li>🎯 focuses on extracting critical insights from data to facilitate optimal, data-driven decision-making</li>
        </ul>
      </div>
    </div>
  );
}

export default AboutMe;

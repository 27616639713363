import React from 'react';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import MainContent from './MainContent';
import Resume from './Resume';
import Projects from './Projects';
import PageWrapper from './PageWrapper';
import './App.css';

function App() {
  return (
    <Router>
      <PageWrapper>
        <Routes>
          <Route path="/" element={<MainContent />} />
          <Route path="/resume" element={<Resume />} />
          <Route path="/projects" element={<Projects />} />
        </Routes>
      </PageWrapper>
    </Router>
  );
}

export default App;

// MainBox.js
import React from 'react';
import './MainBox.css'; // Make sure to import the CSS file for styles

function MainBox({ children }) {
  return (
    <div className="main-box">
      {children} {/* This will render the content passed from the parent component */}
    </div>
  );
}

export default MainBox;

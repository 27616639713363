// Projects.js

import React from 'react';
import './Projects.css'; // Make sure to create and import the Projects.css file
import Navigation from './Navigation'; // Import your Navigation component if it's separate

function Projects() {
  return (
    <div className="projects-page-container">
      <h1 className="projects-title">Portfolio</h1>
      <Navigation resizeForResume={true} />
      <div className="projects-content-box">
        <section className="projects-header">
        </section>
        <div className="projects-container">
          {/* Project 1 */}
          <div className="project">
            <h3>Stock Analysis & Prediction with LSTM and XGBoost</h3>
            <p>This project implements a Stock Analysis and Prediction application using Streamlit, yfinance, Plotly, LSTM networks, XGBoost regressor, VADER sentiment analyzer, and the Fear & Greed index.</p>
            <a href="https://github.com/aroberthumes/GoogleStockPricePrediction" target="_blank" rel="noopener noreferrer">Read More</a>
            </div>
          {/* Project 2 */}
          <div className="project">
            <h3>NYC Yellow Taxi Dashboard and Forecasting Project</h3>
            <p>This project creates a dashboard showing the performance of yellow taxi cabs in Manhattan, New York City for January 2022, and provides a predictive dashboard for February 1st, 2022. The dashboards are made using Tableau, and the forecasting is performed using XGBoost in Python.</p>
            <a href="https://github.com/aroberthumes/NewYorkYellowCab" target="_blank" rel="noopener noreferrer">Read More</a>
            </div>
          {/* Project 3 */}
          <div className="project">
            <h3>AI-based e-commerce product recommendation system</h3>
            <p>Advanced AI recommendation system for e-commerce, utilizing content-based filtering and text processing to suggest products based on detailed product descriptions.</p>
            <a href="https://github.com/aroberthumes/AI-Driven-Product-Advisor" target="_blank" rel="noopener noreferrer">Read More</a>
            </div>
          {/* Project 4 */}
          <div className="project">
            <h3>Advanced Stock Market Analysis and Forecasting Dashboard with Streamlit and Machine Learning</h3>
            <p>This GitHub project features a Streamlit-based dashboard for analyzing and forecasting stock market trends, focusing on Google's stock alongside major indices, using yfinance, Plotly, and machine learning for insightful visualizations and predictions.</p>
            <a href="https://github.com/aroberthumes/GoogleStockPricePrediction_app" target="_blank" rel="noopener noreferrer">Read More</a>
            </div>
          {/* Project 5 */}
          <div className="project">
            <h3>Global COVID-19 Impact Tracker: Cases, Deaths, and Demographics Dashboard</h3>
            <p>This COVID-19 dashboard offers a detailed view of the pandemic, showcasing global and continental case counts, death rates, a visual map of worldwide spread, and an analysis of the virus's impact on the population across five key countries.</p>
            <a href="https://public.tableau.com/app/profile/anthony.humes/viz/COVID-19DashboardPortfolioProject_16429945770230/Dashboard1" target="_blank" rel="noopener noreferrer">Read More</a>
            </div>
          {/* Project 6 */}
          <div className="project">
            <h3>Manhattan's Yellow Taxi Trends: January Performance and February Predictions Dashboard</h3>
            <p>This dashboard visualizes January 2022's yellow taxi activity in Manhattan and forecasts their performance for February 1st, 2022, using Tableau for visualization and XGBoost for predictive analytics.</p>
            <a href="https://public.tableau.com/app/profile/anthony.humes/viz/ForecastedNewYorkCityYellowTaxiRides/Dashboard1Prediction" target="_blank" rel="noopener noreferrer">Read More</a>
            </div>
          {/* Project 7 */}
          <div className="project">
            <h3>Develop, Design, Deploy: My Digital Portfolio</h3>
            <p>Dive into my world of coding adventures! This React-based portfolio is a mix of tech passion, creativity, and cool projects that mark my journey in the software universe.</p>
            <a href="https://github.com/aroberthumes/portfoliosite" target="_blank" rel="noopener noreferrer">Read More</a>
            </div>
          {/* Project 8 */}
          <div className="project">
            <h3>Northwind Database SQL Query Library</h3>
            <p>Discover the 'Northwind Database SQL Query Library', a collection of thoughtfully crafted SQL queries, offering insights and solutions for various data scenarios within the Northwind database.</p>
            <a href="https://github.com/aroberthumes/NorthwindQueries" target="_blank" rel="noopener noreferrer">Read More</a>
            </div>
          {/* Project 9 */}
          <div className="project">
            <h3>Brain Tumor Classification with Keras and Hyperparameter Tuning</h3>
            <p>This repository contains a Python script for classifying brain scan images into four categories: Glioma, Meningioma, No Tumor, and Pituitary. The classification is done using a Keras model based on the VGG16 architecture and hyperparameter optimization is achieved using Keras Tuner.</p>
            <a href="https://github.com/aroberthumes/CancerImageClassifier" target="_blank" rel="noopener noreferrer">Read More</a>
            </div>
          </div>
          </div>
          </div>
            );
            }

export default Projects;
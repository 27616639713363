// Header.js
import './Header.css';
import React from 'react';

function Header() {
  return (
    <header style={{ backgroundImage: 'url("your-background-image-url")' }}>
      <h1>ANTHONY HUMES</h1>
      <p>Data Enthusiast</p>
      <p>Expert in a broad range of data disciplines, encompassing data analysis, data engineering, data science and machine learning</p>
      <p>Demonstrated expertise and proficiency in each field through diverse professional experiences</p>
    </header>
    
  );
}

export default Header;

import React from 'react';
import { useLocation } from 'react-router-dom';
import Header from './Header';
import Navigation from './Navigation';
import SocialLinks from './SocialLinks';
import Footer from './Footer';

const PageWrapper = ({ children }) => {
  const location = useLocation();
  const isAboutPage = location.pathname === '/';

  return (
    <div>
      {isAboutPage && <Header />}
      
      {/* Render the Navigation bar only on the About Me page */}
      {isAboutPage && <Navigation />}

      {/* The main content of the page (e.g., About Me, Resume, Projects) */}
      {children}

      <SocialLinks />
      <Footer />
    </div>
  );
};

export default PageWrapper;

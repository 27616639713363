// MainContent.js
import React from 'react';
import AboutMe from './AboutMe';
import MainBox from './MainBox'; // Import MainBox if it's a separate component

function MainContent() {
  return (
    <main>
      {/* The MainBox component can act as a wrapper for the content that changes */}
      <MainBox>
        {/* You'll include routing or conditional rendering here to switch between different sections */}
        {/* For now, the AboutMe component is rendered as an example */}
        <AboutMe />
      </MainBox>
    </main>
  );
}

export default MainContent;
